<template>
	<div style="">
		<loading v-if="showLoading"></loading>
		<el-table
			height="400"
			ref="multipleTable"
			:data="this.$store.state.tableData.tableData"
			tooltip-effect="dark"
			style="width: 100%"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column v-for="(item, index) in this.$store.state.tableData.outData" :key="index" :prop="item.prop" :label="item.label" :width="item.width">
				<template slot-scope="scope">
					<div v-if="index == 0">{{ scope.row.name[0].lastName }}/{{ scope.row.name[0].firstName }}</div>
					<div v-if="index == 1">{{ scope.row.sex }}</div>
					<div v-if="index == 2">{{ scope.row.country }}</div>
					<div v-if="index == 3">{{ scope.row.date }}</div>
					<div v-if="index == 4">{{ scope.row.idType }}</div>
					<div v-if="index == 5">{{ scope.row.idnumber }}</div>
					<div v-if="index == 6">{{ scope.row.exp }}</div>
					<div v-if="index == 7">{{ scope.row.phone[0].area }}-{{ scope.row.phone[0].phone }}</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import loading from './loading.vue';
import { ref } from 'vue';


export default {
	name: 'selectWorker',
	components: {
		loading
	},
	mounted() {
		console.log(this.$store.state.tableData.outData);
		console.log(this.$store.state.tableData.tableData);
	},
	props: ['innerData', 'outData', 'type'],

	data() {
		return {
			showLoading:false,
			searchText: '',
			PassengerList:[],
			tableData: [
				{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-08',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-06',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}
			],
			multipleSelection: []
		};
	},
	
	methods: {
		toggleSelection(rows) {
			if (rows) {
				rows.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			console.log(this.multipleSelection);
		}
	},
	setup(props) {}
};

//常旅客信息
function getOftenPassengerList() {
	uni.showLoading({
		title: '加载中'
	});
	var Book_PassengerList = storagehelper.getPassengerListStorage(IsChinaCity);
	let parm = {
		Account: util.getAccountCode(),
		isBooke: true,
		IsChinaCity: IsChinaCity
	};
	util.getAjaxRequest('/WXMiniAppApi/GetOftenPassengerList', parm, function(result) {
		console.log(result);
		if (result.code == 0) {
			var PassengerList = JSON.parse(result.data.PassengerList);
			if (PassengerList == '' || PassengerList == null || PassengerList.length == 0) {
				uni.showModal({
					title: '提示',
					content: '您当前还未添加旅客,是否添加？',
					success: function(res) {
						if (res.confirm) {
							jumpEdit('');
							return;
						} else {
						}
					}
				});
			} else {
				for (let item in PassengerList) {
					let checked = false;

					if (Book_PassengerList != null && Book_PassengerList != '') {
						var temp_Arr = Book_PassengerList.filter(function(item_list) {
							return item_list.Man_id === PassengerList[item].PersonCode;
						});
						checked = temp_Arr.length > 0;
					}

					PassengerList[item].checked = checked;
					PassengerList[item].PassengerAgeSectionName = PassengerList[item].PassengerAgeSection == 'CHD' ? '小孩' : '成人';
				}

				that.setData({
					PassengerList: PassengerList
				});
			}
		}
	});
}
</script>

<style></style>
