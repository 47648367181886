<template>
	<div>
		<div class="int-content" style="margin-bottom:140px;">
			<ul class="reserve-flows flex-start flexbox">
				<li :class="(item.current == true ? 'current ' : '') + 'ticket-box  flexbox'" v-for="(item, index) in progress" :key="index">
					<span class="flows-line"></span>
					<a class="flows-text">{{ item.name }}</a>
				</li>
			</ul>
			<div class="flight-comfirm hoel-comfirm com-title">
				<!--预定的酒店信息-->
				<hotelMessage :priceInfo="priceInfo" :HotelBookInfo="HotelBookInfo"></hotelMessage>

				<div class="pb10 mt10">
					<div class="box-title box-title2"><span class="fl">入住信息</span></div>
					<div class="info-box2" v-for="(item, index) in form.HotelPassengerList" :key="index">
						<div class="tit">客房{{ item.RoomIndex }}：{{ item.RoomName }}</div>
						<el-row type="flex" align="middle" v-for="(PassengerItem, index2) in item.PassengerListModel" :key="index2">
							<el-col :span="8">
								<el-row type="flex" align="middle">
									<span class="num">1</span>
									<el-row class="item-list" type="flex" align="middle">
										<div class="item-tit name mr5">姓名：{{ PassengerItem.Man_xingming }}</div>
									</el-row>
								</el-row>
							</el-col>
							<el-col :span="6">
								<span class="contact-person">乘客类型：{{ PassengerItem.Man_type_p == 'ADT' ? '成人' : '儿童' }}</span>
							</el-col>
							<el-col :span="4">
								<span>国籍：{{ PassengerItem.Nationality }}</span>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="pb10">
					<div class="box-title box-title2"><span class="fl">联系人信息</span></div>
					<div class="info-box2">
						<el-row type="flex">
							<el-col :span="10">
								<span class="contact-person">联系人：{{ form.linkman }}</span>
							</el-col>
							<el-col>
								<span class="contact-person">国家区号-手机号码：{{ form.area }}-{{ form.phone }}</span>
							</el-col>
							<el-col>
								<span>联系邮箱：{{ form.email }}</span>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="pb10">
					<div class="box-title box-title2"><span class="fl">附加信息</span></div>
					<div class="info-box2">
						<div v-for="(item,index) in form.hotelRemarkChecked" :key="index">{{ item }}</div>
						<div>{{ form.hoteladdremark }}</div>
						<div>{{ form.remark }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fixed-price-box">
			<div class="price-center ">
				<!--因私时加上（price-private）样式-->
				<div class="left-box">
					<p class="ft16 pt5">
						订单总金额：
						<span class="red ft20 fb price-number">￥{{ priceInfo.TotalPrice }}</span>
						<span class="gray9 ft12 ml20">共{{ priceInfo.AdultCount + priceInfo.ChildCount }}名入住人</span>
					</p>
					<!-- <div class="ft18 mt20" v-if="payTypeList!=null">
						<em>请选择结算方式：</em>
						<el-radio-group v-model="payTypeID">
							<el-radio v-for="(item, index) in payTypeList" :key="index" :label="item.value" :value="item.value">
								{{ item.name }}
								<i class="gray9 ft12 ml10" v-if="item.balanceName != ''">{{ item.balanceName }}:￥{{ item.balance }}</i>
							</el-radio>
						</el-radio-group>
					</div> -->
				</div>

				<div class="text-c btn-box fr">
					<a class="ft16 fb gray3 mr20 btn" href="javascript:;" @click="bindReturn">返回</a>
					<button class="btn next-btn" type="button" @click="bindSubmit">确定预订</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed, ref } from '@vue/composition-api';
import selectWorker from '../../components/selectWorker.vue';
import hotelMessage from './component/hotelMessage.vue';
import { submitOrder } from '@/api/order';
import { payTypeList } from '@/data/staticData';

import { getAccountInfo, getHotelBookingFormInfo, setHotelBookingFormInfo } from '@/utils/localdatas';

var util = require('@/utils/util.js');
var hotelApi = require('@/api/hotelApi.js');
var that;

export default {
	components: {
		hotelMessage
	},
	setup() {},
	data() {
		return {
			radio: '',
			progress: [
				{
					name: '选择航班',
					current: true
				},
				{
					name: '选择报价',
					current: true
				},
				{
					name: '填写资料',
					current: true
				},
				{
					name: '确定订单',
					current: true
				},
				{
					name: '提交订单'
				}
			],
			payTypeList: payTypeList,
			payTypeID: 1,

			HotelBookInfo: {},
			priceInfo: {
				TotalPrice: 0,
				AdultPrice: 0,
				AdultTax: 0,
				ChildPrice: 0,
				ChildTax: 0,
				AdultCount: 1,
				ChildCount: 0,
				day: 0
			},
			form: {}
		};
	},
	created() {
		console.log(this.$route.query);
		that = this;
		that.form = getHotelBookingFormInfo();
		getHotelBookInfo();
		var accountInfo = getAccountInfo();
		getPayTypeList();
		$(window).scrollTop("0");
		// getLedgerAccountCash(accountInfo.CompanyCode);
	},
	methods: {
		bindReturn(e) {
			that.$router.push({ path: '/Hotel/booking' });
		},
		//提交订单
		bindSubmit: function(e) {
			var values = that.form;

			console.log(values);			

			let HotelBookInfo = JSON.stringify(that.HotelBookInfo);
			let HotelPassengerList = JSON.stringify(values.HotelPassengerList);

			// let InsurancelList = JSON.stringify(this.InsurancelList);
			let accountinfo = '';
			if (util.checkLogin()) {
				accountinfo = JSON.stringify(getAccountInfo());
			}
			
			let hotelremark = '';
			if (!util.isNullOrEmpty(values.hotelRemarkChecked) ) {
				for (var i = 0; i < values.hotelRemarkChecked.length; i++) {
					hotelremark += values.hotelRemarkChecked[i] + ',';
				}
			}
			hotelremark+=values.hoteladdremark;
			let parm = {
				HotelPassengerList: HotelPassengerList,
				HotelBookInfo: HotelBookInfo,
				accountinfo: accountinfo,
				
				hotelremark:hotelremark,

				// isneedinsure: values.isneedinsure,
				SendLinkMan: values.linkman,
				SendLinkMobile: values.phone,
				SendLinkEmail: values.email,
				SendLinkRemark: values.remark,

				IsChinaCity: false,
				// isbingPhone: that.isbingPhone,
				// VCode: values.VCode,
				payTypeID: that.payTypeID,
				ordertype: 'hotel',				
			};
			console.log(parm);
			// return;

			// uni.showLoading({
			// 	title: '订单提交中'
			// });

			const loading = this.$loading({
				lock: true,
				text: '订单提交中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			// setTimeout(() => {
			// 	loading.close();
			// }, 2000);
			// return;

			submitOrder(parm, function(result) {
				if (result.code == 0) {
					// let url = '/pages/flight/orderResult/orderResult?id=' + result.data.LR_BillNO + '&payTypeID=' + that.payTypeID;
					// uni.reLaunch({
					// 	url: url
					// });
					that.$router.push({ path: '/Booking/result', query: { id: result.data.LR_BillNO, payTypeID: that.payTypeID } });
				} else if (result.code == -1) {
					that.$confirm(result.msg, '提示', {
						distinguishCancelAndClose: true,
						confirmButtonText: '确定'
					}).then(() => {
						that.$router.push({ path: '/' });
					});
				} else {
					that.$alert(result.msg);
				}
				loading.close();
			});
		}
	}
};

function getHotelBookInfo() {
	hotelApi.GetHotelBookInfo(null, function(result) {
		// console.log(result);;
		// that.isShowloading=false;
		if (result.code == 0) {
			var HotelBookInfo = result.data.HotelBookInfo;
			that.HotelBookInfo = HotelBookInfo;
			that.HotelBookInfo.dateInfo = result.data.dateInfo;

			that.priceInfo.TotalPrice = HotelBookInfo.HotelRoomPriceInfo.RoomPriceInfos[0].TotalAmount;
			that.priceInfo.AdultCount = HotelBookInfo.HotelSearchInfo.AdultCount;
			that.priceInfo.ChildCount = HotelBookInfo.HotelSearchInfo.ChildCount;
			that.priceInfo.day = HotelBookInfo.HotelSearchInfo.Day;
		} else {
			that.$alert(result.msg, {
				confirmButtonText: '确定',
				callback: action => {
					that.$router.push({ path: '/Hotel' });
				}
			});
		}
	});
}
function getPayTypeList() {
	util.postAjaxRequest(
		'/MemberAPI/GetPayTypeList',
		null,
		function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.payTypeList=result.data.PayTypeList;
			}
		}
	);
}
</script>

<style lang="scss">
@import '../../style/IntlAir/index.scss';
@import '../../style/IntlAir/comfirm.scss';
@import '../../style/Hotel/hotel.scss';
</style>
